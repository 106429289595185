@import '~antd/dist/antd.css';

.login-container {
  display: flex;
  flex-direction: column;
  width: 350px; 
  position: relative;
  margin: auto;
  top: 250px;
}

/* .login-container Input, Button {
  width: 100%;
} */
