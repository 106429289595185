@import '~antd/dist/antd.css';

.client-view-container body {
  min-height: 100vh; 
}

.client-view-container .ant-layout, .ant-layout * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.client-view-container .actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.client-view-container .multi-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
}

/* Footer */
.client-view-container .filter-bar-container, .table-container {
  margin: 50px 180px;
}

.client-view-container .footer-text-left{
  box-sizing: border-box;
  flex: 1 1;
  font-size: 15px;
}

.client-view-container .footer-text-left strong {
  color: #1890ff;
}

.client-view-container footer .footer-btns-right {
  display: flex;
  justify-content: space-between;
  width: 250px;
}

.client-view-container .footer-btns-right button {
  border: none;
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  height: 40px;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 2px;
  align-items: center;

  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
}

.client-view-container .footer-btns-right button:focus{
  border: none;
  outline: 0;
}

.footer-container {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  padding: 0 24px;
  line-height: 44px;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  box-shadow: 0 -6px 16px -8px rgb(0 0 0 / 8%), 0 -9px 28px 0 rgb(0 0 0 / 5%), 0 -12px 48px 16px rgb(0 0 0 / 3%);
  transition: width .1.8s cubic-bezier(.645,.045,.355,1);
  width: 100%;
}

/* 
  Modal
*/

.site-card-border-less-wrapper .ant-form-item-label {
  overflow: visible;
}

.ant-modal-content{
  /* height: 600px; */
  overflow: auto;
}

.devices-container .actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

